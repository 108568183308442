import { useQuery } from "@apollo/client"
import clsx from "clsx"
import { ChevronLeft } from "lucide-react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { currency } from "~/common/currency"
import {
  adminEditStudyPackagePath,
  adminStudyPackagesPath,
} from "~/common/paths"
import { getStudyCountDisplay } from "~/common/studyPackage"
import { boolToString } from "~/lib/utils"
import { ButtonLink } from "~/ui/Button"
import Container from "~/ui/Container"
import { TD, TH, Table } from "~/ui/DetailTable"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"

export const AdminStudyPackageScreen_StudyPackageFragment = gql(`
  fragment AdminStudyPackageScreen_StudyPackageFragment on StudyPackage {
    id
    name
    priceCents
    studyCount
    manualCount
    isImageCount
    createdAt
    modalities
    featured
    published
    public
    studiesCsvUrl
    description
    showStudies
    bodyPart {
      id
      name
      uid
    }
  }
`)

const AdminStudyPackageScreen_Query = gql(`
  query AdminStudyPackageScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminStudyPackageScreen_StudyPackageFragment
    }
  }
`)

const AdminStudyPackageScreen = () => {
  const { id } = useParams()
  invariant(id, "expected id")

  const { data, loading, error } = useQuery(AdminStudyPackageScreen_Query, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "StudyPackage")
    return <Error error={error} />

  const studyPackage = getFragmentData(
    AdminStudyPackageScreen_StudyPackageFragment,
    data.node
  )
  const backPath = adminStudyPackagesPath.pattern

  return (
    <div className="flex flex-col gap-8">
      <div
        className={clsx(
          "flex flex-col gap-4",
          "lg:flex-row lg:items-center lg:justify-between"
        )}
      >
        <div className="flex items-center">
          <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
            <ChevronLeft size={28} />
          </Link>
          <H1 margin={false}>{studyPackage.name}</H1>
        </div>

        <div className="flex items-center">
          <ButtonLink
            className="text-sm font-semibold"
            to={adminEditStudyPackagePath({ id })}
          >
            Edit
          </ButtonLink>
        </div>
      </div>
      <HR padding={false} />
      <Container size="md">
        <Table>
          <thead>
            <tr>
              <TH colSpan={2}>Details</TH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TD>Name</TD>
              <TD>{studyPackage.name}</TD>
            </tr>
            <tr>
              <TD>Description</TD>
              <TD>
                <div className="whitespace-pre-wrap">
                  {studyPackage.description}
                </div>
              </TD>
            </tr>
            <tr>
              <TD>Price</TD>
              <TD>
                {studyPackage.priceCents
                  ? currency(studyPackage.priceCents)
                  : ""}
              </TD>
            </tr>
            <tr>
              <TD>Show Studies</TD>
              <TD>{boolToString(studyPackage.showStudies)}</TD>
            </tr>
            {studyPackage.showStudies ? (
              <tr>
                <TD>Study CSV</TD>
                <TD>
                  {studyPackage.studiesCsvUrl && (
                    <Link variant="blue" to={studyPackage.studiesCsvUrl}>
                      Download
                    </Link>
                  )}
                </TD>
              </tr>
            ) : (
              <>
                <tr>
                  <TD>Modality</TD>
                  <TD>
                    <div className="uppercase">
                      {studyPackage.modalities.join(", ")}
                    </div>
                  </TD>
                </tr>
                <tr>
                  <TD>Type</TD>
                  <TD>{studyPackage.isImageCount ? "Image" : "Study"}</TD>
                </tr>
              </>
            )}
            <tr>
              <TD>Count</TD>
              <TD>{getStudyCountDisplay({ studyPackage })}</TD>
            </tr>
            <tr>
              <TD>Body Part</TD>
              <TD>{studyPackage.bodyPart.name}</TD>
            </tr>
            <tr>
              <TD>Featured</TD>
              <TD>{boolToString(studyPackage.featured)}</TD>
            </tr>
            <tr>
              <TD>Published</TD>
              <TD>{boolToString(studyPackage.published)}</TD>
            </tr>
            <tr>
              <TD>Public</TD>
              <TD>{boolToString(studyPackage.public)}</TD>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export default AdminStudyPackageScreen
