import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  matchPath,
} from "react-router-dom"
import invariant from "tiny-invariant"
import * as paths from "~/common/paths"
import { BreadCrumb } from "./components/Breadcrumbs"
import DashboardLayout, {
  ADMIN_ROOT_PATHS,
  ROOT_PATHS,
} from "./layouts/DashboardLayout"
import Layout from "./layouts/Layout"
import { RegistrationLayout } from "./layouts/RegistrationLayout"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import AdminAdminsScreen from "./screens/AdminScreen/AdminAdminsScreen/AdminAdminsScreen"
import AdminAdminsIndexScreen from "./screens/AdminScreen/AdminAdminsScreen/screens/AdminAdminsIndexScreen/AdminAdminsIndexScreen"
import AdminBulkUpdatesScreen from "./screens/AdminScreen/AdminBulkUpdatesScreen/AdminBulkUpdatesScreen"
import AdminBulkUpdatesIndexScreen from "./screens/AdminScreen/AdminBulkUpdatesScreen/screens/AdminBulkUpdatesIndexScreen/AdminUsersIndexScreen"
import AdminNewStudyArchiveScreen from "./screens/AdminScreen/AdminBulkUpdatesScreen/screens/AdminNewStudyArchiveScreen/AdminNewStudyArchiveScreen"
import AdminNewStudyImportScreen from "./screens/AdminScreen/AdminBulkUpdatesScreen/screens/AdminNewStudyImportScreen/AdminNewStudyImportScreen"
import AdminStudyArchiveScreen from "./screens/AdminScreen/AdminBulkUpdatesScreen/screens/AdminStudyArchiveScreen/AdminStudyArchiveScreen"
import AdminStudyImportScreen from "./screens/AdminScreen/AdminBulkUpdatesScreen/screens/AdminStudyImportScreen/AdminStudyImportScreen"
import AdminExportRequestsScreen from "./screens/AdminScreen/AdminExportRequestsScreen/AdminExportRequestsScreen"
import AdminExportRequestScreen from "./screens/AdminScreen/AdminExportRequestsScreen/screens/AdminExportRequestScreen/AdminExportRequestScreen"
import AdminExportRequestsIndexScreen from "./screens/AdminScreen/AdminExportRequestsScreen/screens/AdminExportRequestsIndexScreen/AdminExportRequestsIndexScreen"
import AdminOrdersScreen from "./screens/AdminScreen/AdminOrdersScreen/AdminOrdersScreen"
import AdminOrderScreen from "./screens/AdminScreen/AdminOrdersScreen/screens/AdminOrderScreen/AdminOrderScreen"
import AdminOrdersIndexScreen from "./screens/AdminScreen/AdminOrdersScreen/screens/AdminOrdersIndexScreen/AdminOrdersIndexScreen"
import AdminScreen from "./screens/AdminScreen/AdminScreen"
import AdminStudiesScreen from "./screens/AdminScreen/AdminStudiesScreen/AdminStudiesScreen"
import AdminStudiesIndexScreen from "./screens/AdminScreen/AdminStudiesScreen/screens/AdminStudiesIndexScreen/AdminStudiesIndexScreen"
import AdminStudyScreen from "./screens/AdminScreen/AdminStudiesScreen/screens/AdminStudyScreen/AdminStudyScreen"
import AdminStudyPackagesScreen from "./screens/AdminScreen/AdminStudyPackagesScreen/AdminStudyPackagesScreen"
import AdminEditStudyPackageScreen from "./screens/AdminScreen/AdminStudyPackagesScreen/screens/AdminEditStudyPackageScreen/AdminEditStudyPackageScreen"
import AdminNewStudyPackageScreen from "./screens/AdminScreen/AdminStudyPackagesScreen/screens/AdminNewStudyPackageScreen/AdminNewStudyPackageScreen"
import AdminStudyPackageScreen from "./screens/AdminScreen/AdminStudyPackagesScreen/screens/AdminStudyPackageScreen/AdminStudyPackageScreen"
import AdminStudyPackagesIndexScreen from "./screens/AdminScreen/AdminStudyPackagesScreen/screens/AdminStudyPackagesIndexScreen/AdminStudyPackagesIndexScreen"
import AdminUsersScreen from "./screens/AdminScreen/AdminUsersScreen/AdminUsersScreen"
import AdminEditUserScreen from "./screens/AdminScreen/AdminUsersScreen/screens/AdminEditUserScreen/AdminEditUserScreen"
import AdminUserScreen from "./screens/AdminScreen/AdminUsersScreen/screens/AdminUserScreen/AdminUserScreen"
import AdminUsersIndexScreen from "./screens/AdminScreen/AdminUsersScreen/screens/AdminUsersIndexScreen/AdminUsersIndexScreen"
import { DashboardScreen } from "./screens/DashboardScreen/DashboardScreen"
import EthnicityRaceDistributionScreen from "./screens/EthnicityRaceDistributionScreen"
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen"
import { LoadingScreen } from "./screens/LoadingScreen"
import { LoginScreen } from "./screens/LoginScreen"
import OrdersScreen from "./screens/OrdersScreen/OrdersScreen"
import OrderScreen from "./screens/OrdersScreen/screens/OrderScreen/OrderScreen"
import OrderSuccessScreen from "./screens/OrdersScreen/screens/OrderSuccessScreen/OrderSuccessScreen"
import OrdersIndexScreen from "./screens/OrdersScreen/screens/OrdersIndexScreen/OrdersIndexScreen"
import ProfileScreen from "./screens/ProfileScreen/ProfileScreen"
import EditPasswordScreen from "./screens/ProfileScreen/screens/EditPasswordScreen"
import EditProfileScreen from "./screens/ProfileScreen/screens/EditProfileScreen"
import ProfileScreenIndex from "./screens/ProfileScreen/screens/ProfileScreenIndex"
import { RegistrationScreen } from "./screens/RegistrationScreen"
import ResetPasswordScreen from "./screens/ResetPasswordScreen"
import SearchScreen from "./screens/SearchScreen/SearchScreen"
import ImageSearchResultsScreen from "./screens/SearchScreen/screens/ImageSearchResultsScreen/ImageSearchResultsScreen"
import SearchFormScreen from "./screens/SearchScreen/screens/SearchFormScreen/SearchFormScreen"
import StudySearchResultsScreen from "./screens/SearchScreen/screens/StudySearchResultsScreen/StudySearchResultsScreen"
import StudySearchNewOrderScreen from "./screens/SearchScreen/screens/StudySearchResultsScreen/screens/StudySearchNewOrderScreen/StudySearchNewOrderScreen"
import StudySearchResultScreen from "./screens/SearchScreen/screens/StudySearchResultsScreen/screens/StudySearchResultScreen/StudySearchResultScreen"
import StudySearchResultsIndexScreen from "./screens/SearchScreen/screens/StudySearchResultsScreen/screens/StudySearchResultsIndexScreen/StudySearchResultsIndexScreen"
import SearchesScreen from "./screens/SearchesScreen/SearchesScreen"
import StudyPackagesScreen from "./screens/StudyPackagesScreen/StudyPackagesScreen"
import ImageSearchNewOrderScreen from "./screens/StudyPackagesScreen/screens/ImageSearchNewOrderScreen/ImageSearchNewOrderScreen"
import StudyPackageNewOrderScreen from "./screens/StudyPackagesScreen/screens/StudyPackageNewOrderScreen/StudyPackageNewOrderScreen"
import StudyPackageScreen from "./screens/StudyPackagesScreen/screens/StudyPackageScreen/StudyPackageScreen"
import StudyPackageStudyScreen from "./screens/StudyPackagesScreen/screens/StudyPackageStudyScreen/StudyPackageStudyScreen"
import StudyPackagesIndexScreen from "./screens/StudyPackagesScreen/screens/StudyPackagesIndexScreen/StudyPackagesIndexScreen"
import { ErrorBoundary } from "./ui/ErrorBoundary"

const studyPackageScreens = ({ signedIn }: { signedIn: boolean }) => {
  return (
    <Route
      path={paths.studyPackagesPath.pattern}
      element={<StudyPackagesScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb
                path={path}
                name="Image Packages"
                index={index}
                icon={ROOT_PATHS.imagePackages.icon}
              />
            </>
          )
        },
      }}
    >
      {signedIn && (
        <>
          <Route index={true} element={<StudyPackagesIndexScreen />} />
          <Route
            path={paths.studyPackageStudyPath.pattern}
            element={<StudyPackageStudyScreen />}
            handle={{
              crumb: (path: string, index: number) => {
                const match = matchPath(
                  paths.studyPackageStudyPath.pattern,
                  path
                )
                invariant(match?.params.id, "expected studyPackageId")
                return (
                  <>
                    <BreadCrumb
                      path={`${paths.studyPackagePath({
                        id: match.params.id,
                      })}${window.location.search}`}
                      name="Package Details"
                      index={index}
                    />
                    <BreadCrumb path={path} name="Study" index={index} />
                  </>
                )
              },
            }}
          />
          <Route
            path={paths.studyPackageNewOrderPath.pattern}
            element={<StudyPackageNewOrderScreen />}
            handle={{
              crumb: (path: string, index: number) => {
                const match = matchPath(
                  paths.studyPackageNewOrderPath.pattern,
                  path
                )
                invariant(match?.params.id, "expected studyPackageId")
                return (
                  <>
                    <BreadCrumb
                      path={`${paths.studyPackagePath({
                        id: match.params.id,
                      })}${window.location.search}`}
                      name="Package Details"
                      index={index}
                    />
                    <BreadCrumb
                      path={path}
                      name="Order Summary"
                      index={index}
                    />
                  </>
                )
              },
            }}
          />
        </>
      )}

      <Route
        path={paths.studyPackagePath.pattern}
        element={<StudyPackageScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Package Details" index={index} />
              </>
            )
          },
        }}
      />
    </Route>
  )
}

const sharedSearchRoutes = (
  <>
    <Route
      path={paths.searchPath.pattern}
      element={<SearchScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          const match = matchPath(paths.searchPath.pattern, path)
          return (
            <BreadCrumb
              path={`${paths.searchPath({
                id: match?.params.id || paths.newObjectId,
              })}${window.location.search}`}
              name="Query Builder"
              index={index}
              icon={ROOT_PATHS.searches.icon}
            />
          )
        },
      }}
    >
      <Route element={<SearchFormScreen />} index={true} />
      <Route
        path={paths.imageSearchResultsPath.pattern}
        element={<ImageSearchResultsScreen />}
        handle={{
          crumb: (path: string, index: number) => (
            <BreadCrumb
              path={`${path}${window.location.search}`}
              name="Results"
              index={index}
            />
          ),
        }}
      />
      <Route
        path={paths.imageSearchNewOrderPath.pattern}
        element={<ImageSearchNewOrderScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Order Summary" index={index} />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.studySearchResultsPath.pattern}
        element={<StudySearchResultsScreen />}
        handle={{
          crumb: (path: string, index: number) => (
            <BreadCrumb
              path={`${path}${window.location.search}`}
              name="Results"
              index={index}
            />
          ),
        }}
      >
        <Route element={<StudySearchResultsIndexScreen />} index={true} />
        <Route
          path={paths.studySearchNewOrderPath.pattern}
          element={<StudySearchNewOrderScreen />}
          handle={{
            crumb: (path: string, index: number) => {
              return (
                <>
                  <BreadCrumb path={path} name="Order Summary" index={index} />
                </>
              )
            },
          }}
        />
        <Route
          path={paths.studySearchResultPath.pattern}
          element={<StudySearchResultScreen />}
          handle={{
            crumb: (path: string, index: number) => {
              return (
                <>
                  <BreadCrumb path={path} name="Study" index={index} />
                </>
              )
            },
          }}
        />
      </Route>
    </Route>
  </>
)

const userRoutes = (
  <>
    <Route
      path={paths.dashboardPath.pattern}
      element={<DashboardScreen />}
      handle={{
        crumb: (path: string, index: number) => (
          <BreadCrumb
            path={path}
            name="Home"
            index={index}
            icon={ROOT_PATHS.home.icon}
          />
        ),
      }}
    />
    <Route
      path={paths.profilePath.pattern}
      element={<ProfileScreen />}
      handle={{
        crumb: (path: string, index: number) => (
          <BreadCrumb
            path={path}
            name="Profile"
            index={index}
            icon={ROOT_PATHS.profile.icon}
          />
        ),
      }}
    >
      <>
        <Route element={<ProfileScreenIndex />} index={true} />
        <Route
          path={paths.editProfilePath.pattern}
          element={<EditProfileScreen />}
          handle={{
            crumb: (path: string, index: number) => (
              <BreadCrumb path={path} name="Edit Profile" index={index} />
            ),
          }}
        />
        <Route
          path={paths.editPasswordPath.pattern}
          element={<EditPasswordScreen />}
          handle={{
            crumb: (path: string, index: number) => (
              <BreadCrumb path={path} name="Update Password" index={index} />
            ),
          }}
        />
      </>
    </Route>
    <Route
      path={paths.searchesPath.pattern}
      element={<SearchesScreen />}
      handle={{
        crumb: (path: string, index: number) => (
          <BreadCrumb
            path={path}
            name="Saved Searches"
            index={index}
            icon={ROOT_PATHS.searches.icon}
          />
        ),
      }}
    />
    <Route
      path={paths.ordersPath.pattern}
      element={<OrdersScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb
                path={path}
                name="Orders"
                index={index}
                icon={ROOT_PATHS.orders.icon}
              />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<OrdersIndexScreen />} />
      <Route
        path={paths.orderPath.pattern}
        element={<OrderScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Order Details" index={index} />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.orderSuccessPath.pattern}
        element={<OrderSuccessScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb
                  path={path}
                  name="Order Confirmation"
                  index={index}
                />
              </>
            )
          },
        }}
      />
    </Route>
    {studyPackageScreens({ signedIn: true })}
    <Route
      path={paths.ethnicityRaceDistributionPath.pattern}
      element={<EthnicityRaceDistributionScreen />}
      handle={{
        crumb: (path: string, index: number) => (
          <>
            <BreadCrumb
              path="/"
              name="Home"
              index={index}
              icon={ROOT_PATHS.home.icon}
            />
            <BreadCrumb path={path} name="Statistics" index={1} />
          </>
        ),
      }}
    />
  </>
)

const adminRoutes = (
  <Route
    path={paths.adminPath.pattern}
    element={<AdminScreen />}
    handle={{
      crumb: (path: string, index: number) => {
        return (
          <>
            <BreadCrumb
              path={paths.adminOrdersPath.pattern}
              name="Admin"
              index={index}
              icon={ADMIN_ROOT_PATHS.orders.icon}
            />
          </>
        )
      },
    }}
  >
    <Route
      path={paths.adminOrdersPath.pattern}
      element={<AdminOrdersScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Orders" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminOrdersIndexScreen />} />
      <Route
        path={paths.adminOrderPath.pattern}
        element={<AdminOrderScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Order Details" index={index} />
              </>
            )
          },
        }}
      />
    </Route>
    <Route
      path={paths.adminExportRequestsPath.pattern}
      element={<AdminExportRequestsScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Export Requests" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminExportRequestsIndexScreen />} />
      <Route
        path={paths.adminExportRequestPath.pattern}
        element={<AdminExportRequestScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb
                  path={path}
                  name="Export Request Details"
                  index={index}
                />
              </>
            )
          },
        }}
      />
    </Route>
    <Route
      path={paths.adminStudyPackagesPath.pattern}
      element={<AdminStudyPackagesScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Study Packages" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminStudyPackagesIndexScreen />} />
      <Route
        path={paths.adminStudyPackagePath.pattern}
        element={<AdminStudyPackageScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb
                  path={path}
                  name="Study Package Details"
                  index={index}
                />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.adminNewStudyPackagePath.pattern}
        element={<AdminNewStudyPackageScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Edit" index={index} />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.adminEditStudyPackagePath.pattern}
        element={<AdminEditStudyPackageScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Edit" index={index} />
              </>
            )
          },
        }}
      />
    </Route>

    <Route
      path={paths.adminUsersPath.pattern}
      element={<AdminUsersScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Users" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminUsersIndexScreen />} />
      <Route
        path={paths.adminUserPath.pattern}
        element={<AdminUserScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="User Details" index={index} />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.adminEditUserPath.pattern}
        element={<AdminEditUserScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Edit" index={index} />
              </>
            )
          },
        }}
      />
    </Route>

    <Route
      path={paths.adminAdminsPath.pattern}
      element={<AdminAdminsScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Users" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminAdminsIndexScreen />} />
    </Route>

    <Route
      path={paths.adminStudiesPath.pattern}
      element={<AdminStudiesScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Studies" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminStudiesIndexScreen />} />
      <Route
        path={paths.adminStudyPath.pattern}
        element={<AdminStudyScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Study Details" index={index} />
              </>
            )
          },
        }}
      />
    </Route>

    <Route
      path={paths.adminBulkUpdatesPath.pattern}
      element={<AdminBulkUpdatesScreen />}
      handle={{
        crumb: (path: string, index: number) => {
          return (
            <>
              <BreadCrumb path={path} name="Bulk Updates" index={index} />
            </>
          )
        },
      }}
    >
      <Route index={true} element={<AdminBulkUpdatesIndexScreen />} />
      <Route
        path={paths.adminNewStudyImportPath.pattern}
        element={<AdminNewStudyImportScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Import Studies" index={index} />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.adminStudyImportPath.pattern}
        element={<AdminStudyImportScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb
                  path={path}
                  name="Study Import Details"
                  index={index}
                />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.adminNewStudyArchivePath.pattern}
        element={<AdminNewStudyArchiveScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb path={path} name="Archive Studies" index={index} />
              </>
            )
          },
        }}
      />
      <Route
        path={paths.adminStudyArchivePath.pattern}
        element={<AdminStudyArchiveScreen />}
        handle={{
          crumb: (path: string, index: number) => {
            return (
              <>
                <BreadCrumb
                  path={path}
                  name="Study Archive Details"
                  index={index}
                />
              </>
            )
          },
        }}
      />
    </Route>
  </Route>
)

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route errorElement={<ErrorBoundary />}>
        <Route path={paths.loadingPath.pattern} element={<LoadingScreen />} />

        <Route element={<DashboardLayout />}>
          {sharedSearchRoutes}
          {studyPackageScreens({ signedIn: false })}
        </Route>

        <Route element={<RequireUserSignedIn />}>
          <Route element={<DashboardLayout />}>
            {userRoutes}
            {adminRoutes}
          </Route>
        </Route>
        <Route element={<RequireUserSignedOut />}>
          <Route element={<RegistrationLayout />}>
            <Route path={paths.logInPath.pattern} element={<LoginScreen />} />
            <Route
              path={paths.registrationPath.pattern}
              element={<RegistrationScreen />}
            />
            <Route
              path={paths.forgotPasswordPath.pattern}
              element={<ForgotPasswordScreen />}
            />
            <Route
              path={paths.resetPasswordPath.pattern}
              element={<ResetPasswordScreen />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
)
