import { useEffect } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { logInPath } from "~/common/paths"

const Error = ({ message, error }: { message?: string; error?: any }) => {
  const { currentUser } = useCurrentUserMaybe()
  const navigate = useNavigate()
  console.error(error, message)

  useEffect(() => {
    if (!currentUser && error?.message === "Not Authorized") {
      toast.error("Please sign in to continue.")
      navigate(logInPath.pattern)
    }
  })

  return (
    <div className="text-red-700">{message || "An error has occurred"}</div>
  )
}

export default Error
