import { useMutation, useQuery } from "@apollo/client"
import { FormikHelpers } from "formik"
import { ChevronLeft } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { ModalityEnum } from "~/__generated__/graphql"
import { adminStudyPackagePath, adminStudyPackagesPath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import Error from "~/ui/Error"
import HR from "~/ui/HR"
import { H1 } from "~/ui/Headings"
import { Link } from "~/ui/Link"
import Loading from "~/ui/Loading"
import AdminStudyPackageForm, {
  StudyPackageFormValues,
} from "../../components/AdminStudyPackageForm"
import { AdminStudyPackageScreen_StudyPackageFragment } from "../AdminStudyPackageScreen/AdminStudyPackageScreen"

const AdminEditStudyPackageScreen_Query = gql(`
  query AdminEditStudyPackageScreen_Query($id: ID!) {
    node(id: $id) {
      ...AdminStudyPackageScreen_StudyPackageFragment
    }
  }
`)

export const STUDY_PACKAGE_UPDATE_MUTATION = gql(`
  mutation StudyPackageUpdate($input: StudyPackageUpdateInput!) {
    studyPackageUpdate(input: $input) {
      studyPackage {
        id
        ...AdminStudyPackageScreen_StudyPackageFragment
      }
    }
  }
`)

const AdminEditStudyPackageScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  invariant(id, "expected id")
  const { data, loading, error } = useQuery(AdminEditStudyPackageScreen_Query, {
    variables: {
      id: id,
    },
  })

  const [mutate] = useMutation(STUDY_PACKAGE_UPDATE_MUTATION)

  const onSubmit = async (
    values: StudyPackageFormValues,
    { setFieldError }: FormikHelpers<StudyPackageFormValues>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id,
            studyPackageInput: {
              name: values.name,
              bodyPartId: values.bodyPartId,
              manualCount: values.manualCount,
              isImageCount: values.isImageCount === "true",
              priceCents: Math.round(Number(values.priceDollars) * 100),
              featured: values.featured,
              published: values.published,
              public: values.public,
              studiesCsv: values.studiesCsv || undefined,
              description: values.description,
              showStudies: values.showStudies,
            },
            modalities: (values.modalities as ModalityEnum[]) || [],
          },
        },
      })
      toast.success("Study package has been updated")
      navigate(adminStudyPackagePath({ id }))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  if (loading) return <Loading />
  if (error || !data || data.node.__typename !== "StudyPackage")
    return <Error error={error} />

  const studyPackage = getFragmentData(
    AdminStudyPackageScreen_StudyPackageFragment,
    data.node
  )
  const backPath = adminStudyPackagesPath.pattern

  const initialValues = {
    name: studyPackage.name,
    bodyPartId: studyPackage.bodyPart.uid,
    priceDollars: (studyPackage.priceCents / 100).toString(),
    manualCount: studyPackage.manualCount.toString(),
    isImageCount: studyPackage.isImageCount.toString(),
    modalities: studyPackage.modalities,
    featured: studyPackage.featured,
    published: studyPackage.published,
    public: studyPackage.public,
    description: studyPackage.description || "",
    studiesCsv: "",
    showStudies: studyPackage.showStudies,
    studiesCsvUrl: studyPackage.studiesCsvUrl || "",
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <Link to={backPath} className="mb-0.5 mr-2 text-dodger-blue">
          <ChevronLeft size={28} />
        </Link>
        <H1 margin={false}>Edit {studyPackage.name}</H1>
      </div>
      <HR padding={false} />
      <AdminStudyPackageForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        studiesCsvUrl={studyPackage.studiesCsvUrl || undefined}
      />
    </div>
  )
}

export default AdminEditStudyPackageScreen
